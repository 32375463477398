.vision {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    padding-top: 260px;

    font-family: 'Fira Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;

    color: #000000;

    opacity: 0.4;

    @media (max-width: 768px) {
      padding: 120px 20px 0;
    }
  }

  .title {
    font-family: 'Paralucent';
    font-style: normal;
    font-weight: 500;
    font-size: 100px;
    line-height: 80%;

    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;

    color: #000000;

    @media (max-width: 768px) {
      font-size: 48px;
    }
  }

  .description {
    width: 100%;
    font-family: 'Paralucent';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 160%;

    text-align: center;
    letter-spacing: 0.02em;

    color: #000000;

    .content_line {
      margin: auto;
      position: relative;
      width: fit-content;

      .line {
        opacity: 0.2;
      }

      .line_back {
        position: absolute;
        top: 0;
        overflow: hidden;
        white-space: nowrap;
        left: 0;
        transform: translateX(0);
        background: #000000;
        color: #ffffff;
        opacity: 1;

        transition: width 0.2s ease-in-out;
      }
    }

    @media (max-width: 768px) {
      font-size: 13px;
    }
  }

  .container1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding-top: 120px;
    padding-bottom: 330px;

    .description {
      max-width: 730px;
    }

    @media (max-width: 768px) {
      padding: 120px 20px 120px;
    }
  }

  .container2 {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 48px;
    padding: 120px 80px 330px 80px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    div {
      flex: 1 1;
    }

    .description {
      // padding-left: 20px;
      max-width: 550px;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 120px 20px 120px;

      .description {
        padding-left: 0;
        max-width: 100%;
      }
    }
  }

  .container3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .subtitle {
      padding: 0 60px;
      font-family: 'Paralucent';
      font-style: normal;
      font-weight: 500;
      font-size: 60px;
      line-height: 120%;
      /* identical to box height */

      text-align: center;
      letter-spacing: -0.01em;
      text-transform: uppercase;

      color: #000000;
    }

    .table_description {
      display: grid;
      grid-template-columns: repeat(4, minmax(360px, 1fr));
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);

      .item {
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding: 40px;
        border-right: 1px solid rgba(0, 0, 0, 0.5);

        .item_title {
          font-family: 'Fira Mono';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 160%;
          /* identical to box height, or 64px */

          color: #000000;

          opacity: 0.5;
        }

        .item_description {
          padding-bottom: 220px;
          font-family: 'Paralucent';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -0.02em;

          color: #000000;
        }
      }

      .item:last-child {
        border-right: none;
      }

      @media (max-width: 1440px) {
        grid-template-columns: repeat(2, minmax(360px, 1fr));

        .item {
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }

        .item:nth-child(2n) {
          border-right: none;
        }
      }

      @media (max-width: 720px) {
        grid-template-columns: repeat(1, minmax(360px, 1fr));

        .item:last-child {
          border-bottom: none;
        }
      }
    }

    @media (max-width: 768px) {
      .subtitle {
        padding: 0 20px;
        font-size: 24px;
      }

      .table_description {
        .item {
          flex-direction: row;

          .item_description {
            padding-bottom: unset;
          }
        }
      }
    }
  }

  .container4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 320px;
    padding: 180px 80px 120px 80px;

    .title_w {
      font-family: 'Paralucent';
      font-style: normal;
      font-weight: 500;
      font-size: 60px;
      line-height: 100%;
      /* identical to box height */

      letter-spacing: -0.01em;
      text-transform: uppercase;

      color: #000000;
    }

    .title_b {
      background: #000000;
      font-family: 'Paralucent';
      font-style: normal;
      font-weight: 500;
      font-size: 60px;
      line-height: 100%;
      text-align: right;
      /* identical to box height */

      letter-spacing: -0.01em;
      text-transform: uppercase;

      color: #ffffff;
    }

    .title_box_top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .title_box_bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
    }

    @media (max-width: 768px) {
      padding: 120px 20px 80px;
      gap: 120px;

      .title_w {
        font-size: 24px;
      }

      .title_b {
        font-size: 24px;
      }
    }
  }

  .container5 {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 80px;
    background: #000000;

    font-family: 'Paralucent';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;

    color: #ffffff;

    @media (max-width: 768px) {
      padding: 120px 20px;
      font-size: 24px;
    }
  }

  &.dark {
    .title {
      color: #ffffff;
    }

    .description {
      color: #ffffff;

      .line_back {
        background: #ffffff;
        color: #000000;
      }
    }

    .container1 {
      background: #000000;
      color: #ffffff;
    }

    .container2 {
      background: #000000;
      color: #ffffff;
    }

    .container3 {
      .subtitle {
        color: #ffffff;
      }

      .table_description {
        border-color: rgba(255, 255, 255, 0.5);

        .item {
          border-color: rgba(255, 255, 255, 0.5);

          .item_title {
            color: #ffffff;
          }

          .item_description {
            color: #ffffff;
          }
        }
      }
    }

    .container4 {
      .title_w {
        background: #000000;
        color: #ffffff;
      }

      .title_b {
        background: #ffffff;
        color: #000000;
      }
    }

    .container5 {
      background: #ffffff;
      color: #000000;
    }
  }
}
