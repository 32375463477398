.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    padding-top: 150px;

    font-family: 'Fira Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;

    color: #000000;

    opacity: 0.4;

    @media (max-width: 768px) {
      padding: 80px 20px 0;
    }
  }

  .title {
    padding-top: 65px;
    font-family: 'Paralucent';
    font-style: normal;
    font-weight: 500;
    font-size: 100px;
    line-height: 120%;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;

    color: #000000;

    @media (max-width: 768px) {
      padding-top: 36px;
      font-size: 48px;
    }
  }

  .table {
    margin-top: 90px;
    margin-bottom: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-top: 1px solid rgba(0, 0, 0, 0.3);

    .card {
      padding: 30px 40px 20px;
      display: flex;
      flex-direction: column;
      gap: 36px;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);

      .name {
        width: 100%;
        font-family: 'Paralucent';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        /* identical to box height */

        text-align: left;
        letter-spacing: -0.01em;

        color: #000000;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      .position {
        width: 100%;
        font-family: 'Paralucent';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        /* identical to box height */

        text-align: center;
        letter-spacing: -0.01em;

        color: #000000;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      .school {
        width: 100%;
        font-family: 'Paralucent';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        /* identical to box height */

        text-align: right;
        letter-spacing: -0.01em;

        color: #000000;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      @media (max-width: 768px) {
        padding: 20px 20px 20px;
        gap: 20px;
      }
    }

    .card:nth-child(3n) {
      border-right: none;
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);

      .card:nth-child(3n) {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }

      .card:nth-child(2n) {
        border-right: none;
      }
    }

    @media (max-width: 768px) {
      margin-top: 40px;
      grid-template-columns: repeat(1, 1fr);

      .card:nth-child(3n) {
        border-right: none;
      }
      .card {
        border-right: none;
      }
    }
  }

  &.dark {
    .header {
      color: #ffffff;
    }

    .title {
      color: #ffffff;
    }

    .table {
      border-color: rgba(255, 255, 255, 0.3);
    }

    .card {
      border-color: rgba(255, 255, 255, 0.3);

      .name {
        color: #ffffff;
      }

      .position {
        color: #ffffff;
      }

      .school {
        color: #ffffff;
      }
    }

    @media (max-width: 1024px) {
      .card:nth-child(3n) {
        border-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}
