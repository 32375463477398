.page_scroller {
  position: relative;
}

.page_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1;
  overflow: auto;

  &.light {
    background-color: #fff;
  }

  &.dark {
    background-color: #000;
  }
}
