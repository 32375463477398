@charset 'utf-8';
@import url('https://use.typekit.net/fdj1tum.css');

html {
  font-family: 'Apple SD Gothic Neo', Roboto, 'Noto Sans KR', NanumGothic,
    'Malgun Gothic', paralucent, fira-mono, sans-serif;
  line-height: 1.2;
  word-wrap: break-word;
  --vh: 1vh;
}
body {
  -webkit-font-smoothing: antialiased;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
div,
span,
article,
section,
header,
footer,
aside,
p,
ul,
li,
fieldset,
legend,
label,
a,
nav,
form {
  box-sizing: border-box;
}
ol,
ul,
li {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  height: auto;
  border: 0;
  max-width: 100%;
}
button {
  border: 0;
  background: transparent;
  cursor: pointer;
}
